import {postRequest, postBaseRequest, postCustomRequest, getCustomRequest, getRequest, getPrivateRequest, deleteRequest, getBaseRequest} from './axiosClient'
import { replaceStringWith } from './helpers';

export const sectionCounter = (sectionID) => {
    return postRequest('counter?section_id='+sectionID+'&brand-key='+process.env.brand.brand_key);
};

export const resetToken = () => {
    return getBaseRequest('checkout/reset-token');
};

export const updatePrices = (data) => {
    return postRequest('cart/update-prices', data)
};

export const hireSaving = (data) => {
    return getRequest('quote/hire-saving?brand=' + process.env.brand.brand_key + '&hire-days=' + data.hireDays + '&product-total=' + data.product_total);
};

export const getSurcharges = (data) => {
    return getRequest('surcharges?brand=' + process.env.brand.brand_key + '&delivery-postcode=' + data.deliveryPostcode + '&collection-postcode=' + data.collectionPostcode)
};

export const getMinHire = (data) => {
    return getRequest('quote/min-hire?brand=' + process.env.brand.brand_key + '&postcode=' + data.postcode + '&company=' + data.company)
};

export const getTransport = (data, include_doris_ids = false) => {
    let product_string = "";
    // The payload data cart_products & cart_qty are passed in to calculate the transport costs (including weight and area)
    if(data?.products && data.products.length != 0){
        for(let i=0; i<data.products.length; i++){
            product_string += `&cart_products[]=${data.products[i].product_id}&cart_qty[]=${data.products[i].Quantity}`;
            if(include_doris_ids){
                product_string += `&doris_ids[]=${data.products[i].doris_id}`;
            }
        }
    }
    return getRequest('quote/transport?brand=' + process.env.brand.brand_key + '&delivery-postcode=' + data.deliveryPostcode + '&collection-postcode=' + data.collectionPostcode + '&cart-total-weight=' + data.cartTotalWeight + product_string)
};
export const getProduct = (slug, width, height) => {
    return getRequest('product?brand='+process.env.brand.brand_key+'&product-slug='+slug+'&section-type=category&width='+width+'&height='+height+'&attributes=key_feature');
};

export const getAlternatives = (data) => {
    return postRequest('product/get-product-alternatives', data);
}

export const fetchBlob = async (url, replace = true) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        const base64data = await convertBlobToBase64(blob);

        if (replace) {
            return replaceStringWith(base64data, 'data:image/jpeg;base64,', '');
        }
        return base64data;
    } catch (error) {
        console.error('Error fetching blob:', error);
        return null;
    }
};

export const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
    });
};

export const incrementOrder = (webQuoteID) => {
    return postRequest('order/increment?brand='+process.env.brand.brand_key+'&web-quote-id='+webQuoteID);
};

export const saveOrder = (data) => {
    return postRequest('order/save', data);
};

export const finalisePayment = (data, payment_id) => {
    const objectData = Object.assign({}, data, {payment_id: payment_id});
    // console.log(objectData)
    return postBaseRequest('checkout/finalise-payment', objectData);
};

export const saveChecklistAddress = (data) => {
    return postRequest('order/save-checklist-address', data)
}

export const getCraftyResultsQuery = (data) => {
    return postCustomRequest('https://api.craftyclicks.co.uk/address/1.1/find', data);
}
export const retrieveCraftyClicksAddress = (data) => {
    return postCustomRequest('https://api.craftyclicks.co.uk/address/1.1/retrieve', data);
}

export const convertDorisToHerbertAddress = (address, quote) => {
    return {
        'postcode': address.postCode,
        'company': address.companyName,
        'address_1': address.address1,
        'address_2': address.address2,
        'town': address.town,
        'county': address.county,
        'country': address.country,
        'firstname': address.contactName,
        'email': quote.email,
        'telephone': address.telephone,
        'floor_level': address.floor,
        'lift_access': address.lift,
        'lift_big_enough': address.liftFit,
        'additional_info': address.additionalInfo,
        'parking_restrictions': address.parkingRestrictions,
        'del_col_contact_name': address.contactName,
        'del_col_contact_number': address.telephone
    }
};


export const calculateShipping = (postcode) => {
    const brand = process.env.brand.brand_key;
    return getRequest(`quote/postcode-checker?brand=${brand}&delivery-postcode=${postcode}&collection-postcode=${postcode}&cart-total-weight=0` )
} 

export const checkCarriageId = (carriageId) => {
    return getRequest(`tracking/check_carriage_id?tracking_ref=${carriageId}`);
}

export const convertToDoris = (data) => {
    return postBaseRequest('dashboard/quote/convert', data)
};

export const checkStock = (data) => {
    return postRequest('quote/check-stock', data);
}

export const checkQuoteLimitations = (data) => {
    return postRequest('quote/account-limitations', data);
}

export const updateQuoteFields = (data) => {
    return postBaseRequest('agent/update-quote-details', data);
}